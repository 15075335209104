.app-content{
    padding: 60px 130px;
    text-align: center;
}
.event-img{
    height: 130px;
    width: 130px;
    margin-right: 16px;
    margin-bottom: 16px;
}
.breadcrumb{
    background-color: transparent;
    padding: 0px;
    font-size: 14px;
    margin-bottom: 0;
}
.btn:focus{
    box-shadow: none !important;
}
.card-header{
    text-transform: unset !important;
}
.active-tab .nav-link{
    font-weight: bold;
    border-bottom: 2px solid black;
}
.notification-message{
    font-size: 14px !important;
}
.modal-body{
    font-size: 14px;
}
.modal-footer .btn{
    font-size: 14px;
}
.modal-title{
    font-size: 20px;
}
a:hover{
    text-decoration: none !important;
}
@media(max-width:767px){
    .app-content{
        padding: 30px;
        text-align: center;
    }
}