// page
.cr-page {
  min-height: 100vh;
  &__header {
    @include media-breakpoint-down(sm) {
      flex-direction: column;
      align-items: flex-start;
    }
    display: flex;
    align-items: flex-end;
    align-self: center;
  }

  &__title {
    @include media-breakpoint-down(sm) {
      margin-bottom: 0;
    }
    padding-right: 0.5rem;
    margin-bottom: 1rem;
  }

  &__breadcrumb {
    @include media-breakpoint-down(sm) {
      align-self: flex-start;
    }
    align-self: flex-end;
    padding: 0;
    text-transform: capitalize;
    background: theme-color('light');
  }

  .row {
    // stylelint-disable-next-line
    .col,
    > [class^='col-'] {
      margin-bottom: 1rem;
    }
  }
}

.cr-page-spinner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.opacity{
  opacity: 0.4;
}

.loader{
  position: fixed;
  z-index: 99999;
  top: 95%;
  width: 100%;
  min-height: 900px !important;
  overflow: hidden;
  height: 100%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  text-align: center;
  opacity:0.9; 
}

.shiftLeft{
  margin-left: -7%;
}

.srv-validation-message{
  color: red;
}

.hide{
  display: none;
}
.show{
  display:block;
}

.errorMsg{
  // font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: red;
  text-align: left; 
}

.color-grey{
  background: #b5b2b2 !important;
  border-color: darkgray !important;  
}

.color-grey:hover{
  background: darkgray !important;
}

.pointer{
  cursor: pointer;
}

.grey-hover:hover{
  background-color: lightgray !important;
}

.icon-btn{
  // padding-left: 8px!important;
  // padding-right: 8px!important;
}

.w-20{
  width: 20%!important;
}